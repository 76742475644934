import React, { useEffect, useCallback, useMemo } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import styled from "styled-components"
import Section from "../../components/section.component"
import { fontSizes } from "../structure/theme"
import { Image } from "../../components/images.component"
import breakpoint from "styled-components-breakpoint"
import { useQueryParams } from "use-query-params"
import { resultPageQueryParams } from "./search.utils"
import { HOUSINGTYPE_MAISON } from "../product/product.utils"
import { UNIVERSE_PLOMBERIE_SLUG } from "../universe/universe.utils"
import { Bold, flexCenter } from "../structure/theme/styles.component"
import { joinCodesTracking, useViewPageTracking } from "../thirdParties/gtm/tagManager"
import { Trans, useTranslation } from "../translation/translate.component"
import { BevelBanner } from "../../components/banner/bevelBanner.component"
import DynamicBlock from "../../components/block/dynamicBlock.component"

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 8px;

  ${breakpoint(`medium`)`
    padding-top: 8px;
  `}
`

const BannerText = styled.div`
  > * {
    font-size: ${fontSizes(`xxx_large`)};
    color: white;
  }
`

const BannerImage = styled.div`
  display: none;
  margin-top: 0;

  ${breakpoint(`small`)`
    ${flexCenter};
    padding: 10px;
    border-radius: 50%;
    width: 123px;
    height: 123px;
    background-color: white;
  `}
`

const Title = styled.h1`
  font-weight: 500;
  line-height: normal;
  font-size: 32px;
  margin: 0;

  ${breakpoint(`medium`)`
    font-size: 40px;
  `}
`

export default function ResultsPage ({ data: { serviceClient, allProduct } }) {
  const { t } = useTranslation()
  const [{ u: queryUniverses, h: queryHousingType }, setQuery] = useQueryParams(resultPageQueryParams)

  const pageViewRubrique = useMemo(() => joinCodesTracking(queryUniverses), [])
  useViewPageTracking(pageViewRubrique, ``, `Page Résultat`)

  const onProductFinderChange = useCallback(
    function _onProductFinderChange (housingType, universes) {
      setQuery(
        {
          h: housingType,
          u: universes,
        },
        `replace`,
      )
    },
    [setQuery],
  )

  // fix render issue on static (recette) force relaod
  useEffect(() => {
    if (!queryUniverses && !queryHousingType) {
      setQuery({ h: HOUSINGTYPE_MAISON, u: [UNIVERSE_PLOMBERIE_SLUG] })
    }
  }, [setQuery])

  return (
    <>
      <BevelBanner>
        <Section>
          <Container>
            <BannerText>
              <Title>
                <Trans
                  t={t}
                  i18nKey="common:search.title"
                  components={{
                    bold: <Bold />,
                  }}
                />
              </Title>
            </BannerText>
            <BannerImage>
              <Image file={serviceClient} alt={t(`common:escda.image_alt`)} />
            </BannerImage>
          </Container>
        </Section>
      </BevelBanner>
      {queryUniverses && queryHousingType && (
        <DynamicBlock
          __component="ProductFinder"
          blockTitle={`${t(`common:product.product_finder_title`)} :`}
          blockTitleSeo="h2"
          products={allProduct.nodes}
          onChange={onProductFinderChange}
          initialHousingType={queryHousingType}
          initialUniverses={queryUniverses}
          isSearchPage
        />
      )}
    </>
  )
}

ResultsPage.propTypes = {
  data: PropTypes.shape({
    serviceClient: PropTypes.object.isRequired,
    allProduct: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }).isRequired,
  }).isRequired,
}

export const pageQuery = graphql`
  query {
    serviceClient: file(name: { eq: "elu-service-client" }) {
      childImageSharp {
        fixed(width: 70) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    allProduct(filter: { indexed: { eq: true } }) {
      nodes {
        id
        originalId
        name
        slug
        tunnelSpecificity
        universDescription
        metaDescription
        impactProduct {
          impactId
          variants {
            offerCode
            areaCode
            standardPrice
            offerPrice
          }
        }
        housingType
        universes {
          id
          name
          slug
          primaryColor
          pictoSvg
        }
      }
    }
  }
`
