import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"

export const BevelBanner = styled.div`
  background-color: ${props => props.backgroundColor || props.theme.colors.primary};
  padding-bottom: 40px;
  clip-path: polygon(0 0, 100% 0, 100% 97%, 0% 100%);

  ${breakpoint(`medium`)`
    clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
  `}
`
